module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.nanopharmaceuticals.com","noHash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NanoPharmaceuticals LLC","short_name":"NanoPharmaceuticals","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/nano_square_color.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"15b8091e4d25fd784649ce65969c9ee1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-9ZG8J0P479","head":true,"anonymize":false},
    }]
