// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-board-tsx": () => import("./../../../src/pages/board.tsx" /* webpackChunkName: "component---src-pages-board-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-focus-tsx": () => import("./../../../src/pages/focus.tsx" /* webpackChunkName: "component---src-pages-focus-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ip-tsx": () => import("./../../../src/pages/ip.tsx" /* webpackChunkName: "component---src-pages-ip-tsx" */),
  "component---src-pages-pipeline-tsx": () => import("./../../../src/pages/pipeline.tsx" /* webpackChunkName: "component---src-pages-pipeline-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-trials-tsx": () => import("./../../../src/pages/trials.tsx" /* webpackChunkName: "component---src-pages-trials-tsx" */)
}

